/**
* @ Author: Antoine Herbosa
* @ Create Time: 2021-10-03 15:01:44
* @ Modified by: Antoine Herbosa
* @ Modified time: 2021-12-14 20:41:08
* @ Description:
*/

import './App.css';
import React, { useState, useEffect } from 'react';
import logo from './images/p2p.png'
import logo2 from './images/digital.png'


function App() {
  
  const [score, setScore] = useState({});
  const goals = [
    {
      "step": 10,
      "description": "🍺 Organisation d'un afterwork"
    },
    {
      "step": 25,
      "description": "😤 Yannick arrête de jouer de la flute"
    },
    {
      "step": 35,
      "description": "🧑‍🦲 Hugo se rase les cheveux"
    },
    {
      "step": 42,
      "description": "🎁 Surprise organisée par la pédago" //🕙 Le prochain rendu décalé à 9h42 (PGD)
    },
    {
      "step": 50,
      "description": "🧇 2 gaufres et 2 chocolats offerts"
    },
    {
      "step": 60,
      "description": "🎮 Le BDE organise un samedi Jeux-Vidéo avec tournois & cash prize"
    },
    {
      "step": 70,
      "description": "⚽ Tournois babyfoot avec cash prize"
    },
    {
      "step": 80,
      "description": "🔫 Sortie laser game"
    },
    {
      "step": 100,
      "description": "👞 Hugo, Antoine & les présidents du BDE font un live Versailles (château) - Paris (campus) à pied (20km)"
    },
    {
      "step": 115,
      "description": "🧀 Soirée raclette"
    },
    {
      "step": 135,
      "description": "🤼‍ Combat MMA - Mathieu (avec handicap) vs Volontaires tirés au sort "
    },
    {
      "step": 150,
      "description": "🎭 Toute la Pédago vient déguisée"
    },
    {
      "step": 175,
      "description": "🇳🇱 Go Amsterdam (dès que possible en tenant compte des conditions sanitaires)"
    },
    {
      "step": 200,
      "description": "👨🏼 Antoine se décolore les cheveux"
    },
    {
      "step": 250,
      "description": "👍︎ Hugo, Antoine & les présidents du BDE font une course en autostop Paris (campus) - Lyon (campus) en live"
    },
    {
      "step": 300,
      "description": "🛒 Pear 2 Peer lance Pear store (merch)"
    },
    {
      "step": 350,
      "description": "🇫🇷 Hugo, Antoine & les présidents du BDE font des lives Tour de France des Campus Epitech Digital"
    },
    {"step": 400,
      "description": "💅 Sadry & Téo (PGD2) font leurs Totally Spies !"
    },
    {
      "step": 500,
      "description": "💃🏽 Florent vient en jupe"
    }
  ];
  
  
  useEffect(() => {
    setInterval(() => {
      fetch('https://keepthescore.co/api/wgnptyupbfr/board')
      .then(response => response.json())
      .then(data => setScore(data))
      console.log("update scores")
    }, 3000);
    
  }, []);
  
  useEffect(() => {
    fetch('https://keepthescore.co/api/wgnptyupbfr/board')
    .then(response => response.json())
    .then(data => setScore(data))
  }, []);
  
  function getTeamScore(teamId) {
    let total = 0
    score?.players?.forEach(player => {
      if (player.team === teamId)
      total += player.score
    });
    return total
  }
  
  return (
    <div className="App hogwarts-background">
    <div className="centered-title">
      <h1 className=" ">Collecte Noël - BDE Epitech Digital Paris</h1>
    </div>
    <div className="centered">
    <div className="flex-container">
    <div className="box">
    <ul className="goals-list1">
    {
      goals.map((goal) => {
        console.log(getTeamScore(36182))
        return (
        goal.step <= 120 ?
          <li className={getTeamScore(36182) >= goal.step ? "check" :"" }>
          <span className="step">{goal.step}kg</span>
          <span className="description">{goal.description}</span>
          </li>
          :
          <></>
          )
        })
      }
      </ul>
      </div>
      <div className="box">
      <img src="https://www.restosducoeur.org/wp-content/uploads/2016/04/cropped-logo.png" alt="team" width="65%"></img>
      {
        score?.teams?.map((team) => {
          console.log(team.id)
          return (
            <h2 key={team.id} className="HP-font">{getTeamScore(team.id)} kg donnés</h2>
            )
          })
        }
        <div className="footer">
        <img src={logo} alt="team" width="15%" className="imgFooter"></img>
        <img src={logo2} alt="team" width="35%" className="imgFooter"></img>
        </div>
        </div>
        <div className="box">
        <ul className="goals-list2">
        {
      goals.map((goal) => {
        console.log(goal)
        return (
        goal.step > 120 ?
        <li className={getTeamScore(36182) >= goal.step ? "check" :"" }>
          <span className="step">{goal.step}kg</span>
          <span className="description">{goal.description}</span>
          </li>
          :
          <></>
          )
        })
      }
        </ul>
        </div>
        </div>
        </div>
        </div>
        );
      }
      
      export default App;
